
////////// COLOR SYSTEM //////////

$blue:              #5E50F9;
$blue-1:            #8C95FC;
$blue-2:            #A8B4FD;
$indigo:            #6610f2;
$purple:            #6f42c1;
$pink:              #fd5356;
$red:               #df3849;
$orange:            #fd7e14;
$yellow:            #f6e84e;
$green:             #4fc04d;
$teal:              #58d8a3;
$cyan:              #17a2b8;
$black:             #000;
$white:             #ffffff;
$white-smoke:       #f2f7f8;
$violet:            #49298c;
$darkslategray :    #2e383e;
$dodger-blue :      #3498db;


$colors: (
  blue:             $blue,
  indigo:           $indigo,
  purple:           $purple,
  pink:             $pink,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  teal:             $teal,
  cyan:             $cyan,
  white:            $white,
  gray:             #434a54,
  gray-light:       #aab2bd,
  gray-lighter:     #e8eff4,
  gray-lightest:    #e6e9ed,
  gray-dark:        #0f1531
);


$theme-colors: (
  primary:         #24d5d8,
  secondary:       #aab2bd,
  success:         #33c92e,
  info:            #6569df,
  warning:         #fed961,
  danger:          #fd3258,
  light:           #f5f7fa,
  dark:            #32323d
);


////////// COLOR SYSTEM //////////
$blue-teal-gradient: $white;



////////// COLOR VARIABLES //////////

$content-bg: #f3f5f7;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: #f2f2f2;

////////// COLOR VARIABLES //////////



////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #1da1f2,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b4,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,$content-bg
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS //////////

$TYPE-1 : 'Open Sans', sans-serif;
$TYPE-2 : 'Roboto', sans-serif;
$TYPE-3 : 'Lobster', cursive;

$default-font-size: .875rem;
$text-muted: #8d9498;
$body-color: #12131a;

////////// FONT VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 240px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-dark-bg: theme-color(dark);
$sidebar-dark-menu-color:  #aeaeae;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #c0bbbb;
$sidebar-dark-menu-hover-bg: #39363f;
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #9a94a7;

$sidebar-category-font-size: .6875rem;
$sidebar-category-color: $green;
$sidebar-category-padding: 2.625rem 1.4rem 0.625rem;
$sidebar-submenu-item-padding: 0.4rem 1.4rem;

$sidebar-menu-font-size: .8rem;
$sidebar-menu-padding: 0.6rem 1.4rem;
$nav-link-height: 52px;

$sidebar-submenu-padding: 0 0 0 4.25rem;
$sidebar-submenu-font-size: .75rem;
$sidebar-submenu-item-padding: .75rem 1.4rem;

$sidebar-icon-font-size: .9375rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0rem 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0.6rem 1.4rem;
$sidebar-icon-only-submenu-width: 200px;
$sidebar-icononly-hover-open-bg :$sidebar-dark-bg;
$sidebar-icononly-hover-open-head-bg : lighten($sidebar-dark-bg,5%);
$sidebar-icononly-hover-open-shadow: rgba(113,106,202,.08);
$rtl-sidebar-submenu-padding: 0 2.05rem 0 0;

///////// SIDEBAR ////////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// HORIZONTAL MENU /////////
$horizontal-menu-bg : $white;
$horizontal-menu-padding: 0 1rem;
$horizontal-menu-item-color: #202339;
$horizontal-menu-submenu-color: $horizontal-menu-item-color;
$horizontal-menu-font-size: .875rem;
$horizontal-menu-item-padding: .75rem 0;
$horizontal-menu-icon-font-size: .9375rem;
$horizontal-menu-submenu-item-padding: .85rem 0;
///////// HORIZONTAL MENU /////////

///////// NAVBAR ////////

$navbar-height: 58px;
$navbar-light-color: #202339;
$navbar-font-size: .9375rem;
$navbar-icon-font-size: 1.25rem;

///////// NAVBAR ////////



///////// BUTTONS ////////

$button-fixed-width:        100px;
$input-btn-padding-y:       0.65rem;
$input-btn-padding-x:       0.875rem;
$input-btn-line-height:     1;

$input-btn-padding-y-xs:    .5rem;
$input-btn-padding-x-xs:    .75rem;

$input-btn-padding-y-sm:    0.50rem;
$input-btn-padding-x-sm:    0.81rem;

$input-btn-padding-y-lg:    0.94rem;
$input-btn-padding-x-lg:    1.94rem;

$input-btn-font-size:       .875rem;
$input-btn-font-size-xs:    .625rem;
$input-btn-font-size-sm:    .875rem;
$input-btn-font-size-lg:    .875rem;

$btn-border-raius:          0.125rem;
$btn-border-radius-xs:      .1875rem;
$btn-border-radius-sm:      .1875rem;
$btn-border-radius-lg:      .1875rem;

$social-btn-padding:        10px;
$social-btn-icon-size:      1rem;

///////// BUTTONS ////////



/////////  FORMS /////////

$input-bg:                color(white);
$input-border-radius:     2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: .75rem;

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-border-color: $border-color;
$dropdown-divider-bg:   $border-color;
$dropdown-link-color:   $body-color;
$dropdown-header-color: $body-color;
////////  DROPDOWNS ///////

//////// TABLES ////////

$table-accent-bg: $content-bg;
$table-hover-bg:  $content-bg;
$table-cell-padding: 15px;
$table-border-color: $border-color;

$table-inverse-bg: theme-color(dark);
$table-inverse-color: color(white);

//////// TABLES ////////



////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$boxed-container-width: 96%;
$border-property: 1px solid $border-color;
$card-spacing-y: 1.5rem;
$card-padding-y: 1.5rem;
$card-padding-x: 0.9375rem;

$grid-gutter-width: 20px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y:              0.56rem;
$breadcrumb-padding-x:              1.13rem;
$breadcrumb-item-padding:           .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-font-size:              $default-font-size;
$breadcrumb-bg:                     transparent;
$breadcrumb-border-color:           $border-color;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-700;
$breadcrumb-divider:                "/";

// custom styles
$breadcrumb-custom-padding-y:       0;
$breadcrumb-custom-padding-x:       0;
$breadcrumb-custom-item-padding-y:  0.56rem;
$breadcrumb-custom-item-padding-x:  10px;
$breadcrumb-custom-item-color:      $black;
$breadcrumb-item-bg:                #dbe3e6;

////////// BREAD CRUMBS VARIABLES //////////



////////// MODALS VARIABLES //////////

$modal-inner-padding:               15px;
$modal-dialog-margin:               10px;
$modal-dialog-margin-y-sm-up:       30px;
$modal-title-line-height:           $line-height-base;
$modal-content-bg:                  $content-bg;
$modal-content-box-shadow-xs:       0 3px 9px rgba($black,.5);
$modal-content-box-shadow-sm-up:    0 5px 15px rgba($black,.5);

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .5;

$modal-header-border-color:         $border-color;
$modal-content-border-color:        $border-color;
$modal-footer-border-color:         $border-color;

$modal-header-border-width:         $border-width;
$modal-content-border-width:        $border-width;
$modal-footer-border-width:         $border-width;

$modal-header-padding-x:              26px;
$modal-header-padding-y:              25px;

$modal-body-padding-x:                26px;
$modal-body-padding-y:                35px;

$modal-footer-padding-x:              31px;
$modal-footer-padding-y:              15px;

$modal-lg:                          90%;
$modal-md:                          500px;
$modal-sm:                          300px;
$modal-transition:                  transform .4s ease;

////////// MODALS VARIABLES //////////


////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size:     .75rem;
$tooltip-padding-y:     .4rem;
$tooltip-padding-x:     .75rem;
$tooltip-border-radius: .375rem;
$tooltip-bg:  theme-color(dark);
$tooltip-arrow-color: $tooltip-bg;

////////// TOOLTIP VARIABLES //////////


////////// ACCORDION VARIABLES //////////
$accordion-header-bg: #f5f5f5;
////////// ACCORDION VARIABLES //////////


////////// POPOVER VARIABLES //////////

//default styles
$popover-bg:       theme-color(dark);
$popover-border-color:    $border-color;
$popover-arrow-color:     $popover-bg;
$popover-arrow-outer-color: $popover-border-color;
$popover-header-bg:   $popover-bg;

////////// POPOVER VARIABLES //////////